<template>
  <b-container
    fluid
  >
    <b-row>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Fiscal Year"
          label-for="filter_budgets_fiscal"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_budgets_fiscal"
            v-model="itemCenter.fiscal_name"
            type="text"
            disabled
            debounce="1000"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Budget Center"
          label-for="filter_budgets_budget"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_budgets_budget"
            v-model="itemCenter.budget_name"
            type="text"
            disabled
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="px-1"
        order="1"
        order-md="2"
      >
        <b-form-group
          label="Item Center"
          label-for="filter_budgets_item"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_budgets_item"
            v-model="itemCenter.item_name"
            type="text"
            disabled
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="2"
        order-md="1"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filter_status"
            v-model="tableTransaction.filter.status"
            :options="lists.statuses"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select status here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        class="px-1 mb-2"
        order="3"
      >
        <b-overlay
          rounded
          opacity="0.1"
          spinner-small
          class="d-inline"
          spinner-variant="warning"
          :show="exporter.excel.exporting"
        >
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :class="[
              'btn btn-success btn-sm', {
                'btn-outline-success disabled': exporter.excel.exporting || tableTransaction.totalRows <= 0
              }
            ]"
            :fetch="excelProvider"
            :before-generate="onStartExcelExport"
            :before-finish="onFinishExcelExport"
            :fields="exporter.excel.fields"
            :escape-csv="exporter.excel.escape"
            :type="exporter.excel.type"
            :header="exporter.excel.header"
            :footer="exporter.excel.footer"
            :name="excelFileName"
            :worksheet="itemCenter.item_name"
          >
            {{ exporter.excel.exporting ? 'Exporting' : 'Export As Excel' }}
          </json-excel>
        </b-overlay>
      </b-col>

    </b-row>

    <b-row
      v-if="currentTabIndex === 1"
      class="mt-1"
    >

      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableTransaction"
          hover
          small
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableTransaction.perPage"
          :current-page="tableTransaction.currentPage"
          :items="tableTransactionProvider"
          :fields="tableTransaction.fields"
          :sort-by.sync="tableTransaction.sortBy"
          :sort-desc.sync="tableTransaction.sortDesc"
          :sort-direction="tableTransaction.sortDirection"
          :filter="tableTransaction.filter"
          :filter-included-fields="tableTransaction.filterOn"
          :busy="tableTransaction.busy"
        >
          <template #cell(index)="row">
            {{ tableTransaction.currentPage * tableTransaction.perPage - tableTransaction.perPage + (row.index + 1) }}
          </template>

          <template #cell(active)="row">
            {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(invoice_amount)="row">
            <div
              class="text-md-nowrap text-right"
              style="min-width: 100px"
            >
              {{ row.value }}
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableTransaction.perPage"
          :options="tableTransaction.pageOptions"
          size="sm"
          class="w-100 w-md-25"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableTransaction.currentPage"
          :total-rows="tableTransaction.totalRows"
          :per-page="tableTransaction.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { BOItemCenterService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'BoItemCentersTranctions',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 0
      }
    },
    status: {
      type: String,
      default: () => {
        return 'Pending'
      }
    },
    itemCenter: {
      type: Object,
      default: () => {
        return {
          id: null,
          fiscal_year: null,
          fiscal_name: null,
          budget_center: null,
          budget_name: null,
          item: null,
          item_name: null,
          active: 0
        }
      }
    }
  },

  data () {
    return {
      lists: {
        statuses: [
          {
            value: 'Pending',
            text: 'Pending'
          },
          {
            value: 'Released',
            text: 'Spent'
          }
        ]
      },
      exporter: {
        busy: false,
        pdf: {
          exporting: false,
          data: null
        },
        excel: {
          exporting: false,
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            'Voucher Number': {
              field: 'voucher.voucher_number'
            },
            'Document Number': {
              field: 'document_number'
            },
            'Item Center': {
              field: 'item_center.item.item_name'
            },
            'Business Partner': {
              field: 'bp_name'
            },
            'Invoice Amount': {
              field: 'invoice_amount',
              callback: amount => this.numberFormatter(amount)
            },
            Particulars: {
              field: 'particulars'
            },
            'Requested By': {
              field: 'requestor',
              callback: requestedBy => this.userCurrentRequestor(requestedBy)
            },
            'Requested At': {
              field: 'created_at',
              callback: createdAt => this.dateTimeFormatter(createdAt)
            }
          },
          footer: []
        }
      },
      tableTransaction: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          item_center: this.itemCenter.id,
          status: this.status
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'voucher.voucher_number', label: 'Voucher Number', class: 'text-center font-weight-bolder', sortable: true },
          { mobile: 2, key: 'document_number', label: 'Document Number', class: 'text-center' },
          { mobile: 3, key: 'bp_name', label: 'Business Partner' },
          { mobile: 4, key: 'invoice_amount', formatter: this.numberFormatter },
          { mobile: 5, key: 'particulars' },
          { mobile: 6, key: 'requestor', label: 'Requested By', formatter: this.userCurrentRequestor },
          { mobile: 7, key: 'created_at', label: 'Requested At', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    excelFileName () {
      return `${this.itemCenter.budget_name}-${this.itemCenter.fiscal_name}-${this.itemCenter.item_name}`
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableTransactionProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableTransaction.busy = true
      return await BOItemCenterService.show(
        filter.item_center,
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          status: filter.status
        })
      ).then(({ data }) => {
        this.tableTransaction.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableTransaction.totalRows = 0
        return []
      }).finally(() => {
        this.tableTransaction.busy = false
      })
    },

    async excelProvider () {
      if (this.exporter.busy || this.tableTransaction.totalRows <= 0) {
        return null
      }
      this.exporter.busy = this.exporter.excel.exporting = true
      return await BOItemCenterService.exportRequests(
        this.tableTransaction.filter.item_center,
        this.objectToUrl({
          status: this.tableTransaction.filter.status
        })
      ).then(({ data: { items } }) => {
        if (items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported'
          )
        }
        return items
      }).catch(() => {
        return []
      }).finally(() => {
        this.exporter.busy = this.exporter.excel.exporting = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy || this.tableTransaction.totalRows <= 0) {
        return null
      }
      this.exporter.excel.header = [
        'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INCORPORATED',
        `${this.itemCenter.item_name}`,
        `${this.itemCenter.budget_name} - ${this.itemCenter.fiscal_name}`,
        `${this.tableTransaction.filter.status}`,
        '&nbsp;'
      ]
      this.exporter.excel.footer = []
    },

    onFinishExcelExport () {
    }

  }

}
</script>
